import React, { useRef, useState, useEffect } from 'react';
import '../assets/aboutstyle.css';
import gambar1 from '../assets/img/gambar1.jpg';
import gambar2 from '../assets/img/gambar2.jpg';
import gambar3 from '../assets/img/gambar3.jpg';
import DetailImage from './DetailImage'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import FooterComponent from './FooterComponent';
function AboutComponent() {
    const [showContent, setShowContent] = useState(true)
    const [textimage, setTextImage] = useState("Business Processes Discovery")
    const [descimage, setDescimage] = useState("Provides Consultation services to assist our customer in identifying Business Processes Enhancement areas.")
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const handleSlideChange = (swiper) => {
        setActiveSlideIndex(swiper.activeIndex);
        if (swiper.activeIndex == 0) {
            setTextImage("Business Processes Discovery")
            setDescimage("Provides Consultation services to assist our customer in identifying Business Processes Enhancement areas.")
        }
        else if (swiper.activeIndex == 1) {
            setTextImage("Business Processes Enhancement")
            setDescimage("Provide Business Processes Improvement Blueprint to our valuable clients.")
        }
        else if (swiper.activeIndex == 2) {
            setTextImage("Business Processes Automation and Sustainability")
            setDescimage("Support our client growth with Information-Led Digital Transformation with Processes Automation.")
        }
    };
    useEffect(() => {
        console.log(localStorage.getItem('lang'));
    }, [])

    const showImage = () => {
        setShowContent(false)

    }
    return (
        <div>
            {showContent == true ? (
                <div>
                    {localStorage.getItem('lang') === "ind" ? (<div>
                        <div className="container">
                            <div class="content1 mt-5">
                                <div class="element d-flex justify-content-evenly">
                                    <div className="col-md-3 col-5" onClick={() => showImage()}>
                                        <img src={gambar1} className='img-fluid' />
                                    </div>

                                    <div class="isi col-6">
                                        <h5>Proses Riset Bisnis</h5>
                                        <p>Menyediakan layanan Konsultasi untuk membantu pelanggan kami dalam mengidentifikasi area Peningkatan Proses Bisnis.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="content3 mt-5">
                                <div class="element d-flex justify-content-evenly">
                                    <div className="col-md-3 col-5" onClick={() => showImage()}>
                                        <img src={gambar2} className='img-fluid' />
                                    </div>
                                    <div class="isi col-6">
                                        <h5>Peningkatan Proses Bisnis</h5>
                                        <p>Berikan Cetak Biru Peningkatan Proses Bisnis kepada klien kami yang berharga.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="content3 mt-5">
                                <div class="element d-flex justify-content-evenly">
                                    <div className="col-md-3 col-5" onClick={() => showImage()}>
                                        <img src={gambar3} className='img-fluid' />
                                    </div>
                                    <div class="isi col-6">
                                        <h5>Otomasi dan Keberlanjutan Proses Bisnis.</h5>
                                        <p>Dukung pertumbuhan klien kami dengan Transformasi Digital Berbasis Informasi dengan Otomasi Proses.</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="d-flex justify-content-center mt-5">
                            <div class="box1 col-md-10 ">
                                <h4 class="judul1">TENTANG KAMI</h4>

                                <p class="keterangan">
                                ACCEL WORKS INDONESIA  bekerja secara langsung dengan pelanggan untuk memberikan pelayanan yang terbaik dalam mengidentifikasi perkembangan peluang bisnis mereka dengan riset serta memberikan solusi untuk membantu perjalanan digital mereka menuju digital     
                                    Information-led Digital Transformation.</p>

                            </div>
                        </div>
                        <div className=" d-flex justify-content-center mt-5">
                            <div class="urut col-md-10 col-12">
                                <h4 className='judul1'>VISI KAMI</h4>

                                <ul>
                                    <li>F - Fokus dalam memberikan konsultasi praktis yang terjangkau dalam Peningkatan Proses Bisnis.</li>
                                    <li>I - Memberi Nilai Tambah kepada pelanggan kami melalui solusi Teknologi Informasi terkini. </li>
                                    <li>T - Membantu pelanggan dalam Mentransformasi operasi bisnis mereka melalui Otomasi untuk mencapai yang lebih baik
                                        efisiensi.</li>
                                </ul>
                            </div>
                        </div>
                    </div>) : (<div>
                        <div className="container">
                        <div class="content1 mt-5 ">
                            <div class="element d-flex justify-content-evenly">
                                <div className="col-md-3 col-5" onClick={() => showImage()}>
                                    <img src={gambar1} className='img-fluid' />
                                </div>

                                <div class="isi col-6">
                                    <h5>Business Processes Discovery</h5>
                                    <p>Provides Consultation services to assist our customer in identifying Business Processes Enhancement areas.</p>
                                </div>
                            </div>
                        </div>
                        <div class="content3 mt-5">
                            <div class="element d-flex justify-content-evenly">
                                <div className="col-md-3 col-5" onClick={() => showImage()}>
                                    <img src={gambar2} className='img-fluid' />
                                </div>
                                <div class="isi col-6">
                                    <h5>Business Processes Enhancement</h5>
                                    <p>Provide Business Processes Improvement Blueprint to our valuable clients.</p>
                                </div>
                            </div>
                        </div>
                        <div class="content3 mt-5">
                            <div class="element d-flex justify-content-evenly">
                                <div className="col-md-3 col-5" onClick={() => showImage()}>
                                    <img src={gambar3} className='img-fluid' />
                                </div>
                                <div class="isi col-6">
                                    <h5>Business Processes Automation and Sustainability.</h5>
                                    <p>Support our client growth with Information-Led Digital Transformation with Processes Automation.</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="d-flex justify-content-center mt-5">
                        <div class="box1 col-md-10 ">
                            <h4 class="judul1">ABOUT US</h4>

                            <p class="keterangan">ACCEL WORKS WORLDWIDE PTE LTD work closely with our customers to deliver high value services assisting them to achieve business growth through Business Processes Automation and Information-Led Digtial -Transformation.</p>

                        </div>
                    </div>
                    <div className=" d-flex justify-content-center mt-5">
                        <div class="urut col-md-10 col-12">
                            <h4 className='judul1'>OUR VISION</h4>

                            <ul>
                                <li>F - Focus in providing affordable practical consultancy in Business Processes Improvement.</li>
                                <li>I - Add Value to our customers through latest Information Technology solution. </li>
                                <li>T - Assist customers in Transforming their business operation through Automation to achieve better
                                    efficiency.</li>
                            </ul>
                        </div>
                    </div>
                    </div>)}
                </div>) : (
                <div className='mt-5'>
                    <div>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}

                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                            onSlideChange={handleSlideChange}
                            style={{ width: '500px' }}
                        >
                            <SwiperSlide>
                                <div className='col-12'>
                                    <img src={gambar1} className='img-fluid' />
                                </div>

                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='col-12 '>
                                    <img src={gambar2} className='img-fluid' />
                                </div>

                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='col-12'>
                                    <img src={gambar3} className='img-fluid' />
                                </div>

                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className='text-center mt-3'>
                        <h4>{textimage}</h4>
                        <p>{descimage}</p>
                    </div>


                </div>
            )}


            <FooterComponent />
        </div>

    )
}

export default AboutComponent
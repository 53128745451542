import logo from './logo.svg';
import NavbarComponent from './components/NavbarComponent';
import { BrowserRouter, Switch, Routes, Route, Link, redirect } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import MainContent from './components/MainContent';
import FooterComponent from './components/FooterComponent';
import 'animate.css';
import HomeComponent from './components/HomeComponent';
import AboutComponent from './components/AboutComponent';
import ServiceComponent from './components/ServiceComponent';
import ContactComponent from './components/ContactComponent';
import DetailImage from './components/DetailImage';

function App() {
  return (
    <div className="App">

      <BrowserRouter>
      <NavbarComponent />
      <Routes>
       
       <Route path="/contact" component={ContactComponent} />
       <Route path="/detail" component={DetailImage} />
      </Routes>
       
      </BrowserRouter>

    </div>




  );
}

export default App;

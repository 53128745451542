import React, { useState, useEffect } from 'react';
import '../assets/navbar.css';
import logo from '../assets/img/accelworksindonesialogofix.png';
import '../assets/maincontent.css';
import '../assets/homestyle.css';
import AboutComponent from './AboutComponent';
import HomeComponent from './HomeComponent';
import { useTranslation, Trans } from 'react-i18next';
import 'animate.css';
import ContactComponent from './ContactComponent';
import MainContent from './MainContent';
import ServiceComponent from './ServiceComponent';
import Dropdown from 'react-bootstrap/Dropdown';
import DetailImage from './DetailImage';
import NavDropdown from 'react-bootstrap/NavDropdown';
function NavbarComponent() {
    const [showPage, setShowPage] = useState(false);
    const [language, setLanguage] = useState('eng')
    const [page, setPage] = useState('home');
    const lng = navigator.language;
    // i18n.changeLanguage(lng);
    useEffect(() => {

        setShowPage(true);
    }, [page]);

    const handleClick = (page) => {
        setPage(page)
    }

    const handleChangeLang = (event) => {
        localStorage.setItem('lang', event.target.value)
        setLanguage(event.target.value);

    };



    return (
        <div>
            {language == "ind" ? (
                <div>
                    <div className='nav d-flex justify-content-end'>
                        <div className='col-md-7 col-10 d-flex  justify-content-between pt-3 '>
                            <div className="col-1">
                                <p onClick={() => handleClick('home')}>BERANDA</p>
                            </div>
                            <div className="col-1">
                                <p onClick={() => handleClick('about')}>TENTANG</p>
                            </div>
                            <div className="col-1">
                                <p onClick={() => handleClick('service')}>LAYANAN</p>
                            </div>
                            <div className="col-1">
                                <p onClick={() => handleClick('doclink')}>DOCLINK</p>
                            </div>
                            <div className="col-1">
                                <p onClick={() => handleClick('contact')}>KONTAK</p>
                            </div>
                            <div className="col-md-4  ">
                                <select id="language" value={language} onChange={handleChangeLang} >
                                    <option value="eng" >English</option>
                                    <option value="ind" onClick={() => alert('helloo')}>Bahasa Indonesia</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="content text-dark">
                        <div className="d-flex justify-content-evenly align-items-center">
                            <div className="col-md-5 col-5 mt-2">
                                <img src={logo} alt="" className='img-fluid' />
                                <p className='contenttext2 fw-bold lh-1 mt-3'>MANAJEMEN PROSES BISNIS</p>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                                <p className='contenttext align-self-end'>HUBUNGI KAMI SEKARANG UNTUK PERTEMUAN</p>
                                <p className='contenttext fw-bold align-self-end'>+62 2183709270</p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className='nav d-flex justify-content-end '>
                        <div className='col-md-7 col-10 d-flex  justify-content-between pt-3 '>
                            <div className="col-1">
                                <p onClick={() => handleClick('home')}>HOME</p>
                            </div>
                            <div className="col-1">
                                <p onClick={() => handleClick('about')}>ABOUT</p>
                            </div>
                            <div className="col-1">
                                <p onClick={() => handleClick('service')}>SERVICE</p>
                            </div>
                            <div className="col-1">
                                <p onClick={() => handleClick('doclink')}>DOCLINK</p>
                            </div>
                            <div className="col-1">
                                <p onClick={() => handleClick('contact')}>CONTACT</p>
                            </div>
                            <div className="col-4 ">
                                <select id="language" value={language} onChange={handleChangeLang} >
                                    <option value="eng" >English</option>
                                    <option value="ind" onClick={() => alert('helloo')}>Bahasa Indonesia</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="content text-dark">
                        <div className="d-flex justify-content-evenly align-items-center">
                            <div className="col-md-5 col-5 mt-2">
                                <img src={logo} alt="" className='img-fluid' />
                                <p className='contenttext2 fw-bold lh-1 mt-3'>​BUSINESS​ PROCESS  MANAGEMENT</p>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                                <p className='contenttext align-self-end'>CALL US TODAY FOR AN APPOINTMENT</p>
                                <p className='contenttext fw-bold align-self-end'>+62 2183709270</p>
                            </div>
                        </div>
                    </div>
                </div>)}
            <div className='animate_animated animate_fadeIn'>
                {page === 'home' &&
                    <div className='animate_animated animate_fadeIn'>
                        <HomeComponent />
                    </div>}
                {page === 'about' &&
                    <div className='animate_animated animate_fadeIn'>
                        <AboutComponent />
                    </div>}
                {page === 'service' &&
                    <div className='animate_animated animate_fadeIn'>
                        <ServiceComponent />
                    </div>}
                {page === 'doclink' &&
                    <div className='animate_animated animate_fadeIn'>
                        <MainContent />
                    </div>}
                {page === 'contact' &&
                    <div className='animate_animated animate_fadeIn'>
                        <ContactComponent />
                    </div>}
            </div>


        </div>

    )
}

export default NavbarComponent
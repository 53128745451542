import React from 'react';
import gamser1 from '../assets/img/gamser1.webp';
import gamser2 from '../assets/img/gamser2.webp';
import gamser3 from '../assets/img/gamser3.webp';
import gamser4 from '../assets/img/gamser4.webp';
import gamser5 from '../assets/img/gamser5.webp';
import gamser6 from '../assets/img/gamser6.jpg';
// import gamser5 from '../assets/img/gamser5.webp';
import '../assets/servicestyle.css';
import FooterComponent from './FooterComponent';

function ServiceComponent() {
    return (
        <div>
            {localStorage.getItem('lang') == "ind" ? (<div>
                <div className="container">
                    <div className="headservice col-7 mt-5 offset-1">
                        <h1 className='title'>Layanan</h1>
                        <p className='mt-5 lh-lg'>Accel Works Company Limited menyediakan peningkatan proses bisnis dan layanan otomatisasi kepada pelanggan kami, membantu mereka dalam Transformasi Digital Berbasis Informasi. Jika perusahaan Anda ingin mendapatkan nilai dari Transformasi Digital yang Dipimpin Informasi, silakan tinggalkan pesan kepada kami di bagian kontak kami, sehingga kami dapat mengatur waktu untuk membantu Anda dalam perjalanan dan kami serta Beyond-Altec telah berada di pasar untuk lebih banyak lagi. lebih dari 30 tahun dengan produk teknologi yang telah terbukti dengan baik dan pengalaman implementasi untuk memastikan Anda memiliki perjalanan yang bermanfaat.</p>
                    </div>

                </div>
                <div className="boxservice container mt-5">
                     <div className="container d-flex justify-content-evenly px-5 ">
                         <div className='col-3'>
                             <div className='col-11'>
                                 <img src={gamser1} alt="" className='img-fluid' />
                             </div>
                             <div className='col-11 mt-4'>
                                 <p className='bottombox fs-5 p-2 pb-5'>Konsultasi Peningkatan Proses Bisnis</p>
                             </div>

                         </div>
                         <div className='col-3'>
                             <div className='col-11'>
                                 <img src={gamser2} alt="" className='img-fluid' />
                             </div>
                             <div className='col-11 mt-4'>
                                 <p className='bottombox fs-5 p-2 pb-5'>Otomasi Proses Bisnis</p>
                             </div>

                         </div>

                         <div className='col-3'>
                             <div className='col-11'>
                                 <img src={gamser3} alt="" className='img-fluid' />
                             </div>
                             <div className='col-11 mt-4'>
                                 <p className='bottombox fs-5 p-2 pb-5'>Otomasi Manajemen Kontak dan Dokumen</p>
                             </div>

                         </div>
                         <div className='col-3'>
                             <div className='col-11'>
                                 <img src={gamser4} alt="" className='img-fluid' />
                             </div>
                             <div className='col-11 mt-4'>
                                 <p className='bottombox fs-5 p-2 pb-5'>Solusi Otomatisasi Sumber Daya Manusia</p>
                             </div>

                         </div>
                     </div>
                     <div className=" box d-flex px-5 mt-5">
                         <div className='col-3'>
                             <div className='col-11'>
                                 <img src={gamser5} alt="" className='img-fluid' />
                             </div>
                             <div className='col-11 mt-4'>
                                 <p className='bottombox fs-5 p-2 pb-5'>AP, AR, dan Otomasi Pesanan Penjualan.</p>
                             </div>

                         </div>
                         <div className='col-3'>
                             <div className='col-11'>
                                 <img src={gamser6} alt="" className='img-fluid' />
                             </div>
                             <div className='col-11 mt-4'>
                                 <p className='bottombox fs-5 p-2 pb-5'>
                                     Transformasi dan Otomasi Digital Lainnya.</p>
                             </div>
                         </div>
                     </div>

                 </div>
            </div>) : (<div>
                <div className="container">
                    <div className="headservice col-7 mt-5 offset-1">
                        <h1 className='title'>Services</h1>
                        <p className='mt-5 lh-lg'>Accel Works Company Limited provides business process enhancement and automation services to our customers, assisting them in the Information-Led Digital Transformation. If your company want to gain value from Information -Led Digital Transformation, please do leave us message at our contact section, so that we could arrange a time to assist you in the journey and we and Beyond-Altec has been in the market for more than 30 years with well proven technology product and implementation experience to make sure you have a fruitful journey.</p>
                    </div>

                </div>
                <div className="boxservice container mt-5">
                    <div className="container d-flex justify-content-evenly px-5 ">
                        <div className='col-3'>
                            <div className='col-11'>
                                <img src={gamser1} alt="" className='img-fluid' />
                            </div>
                            <div className='col-11 mt-4'>
                                <p className='bottombox fs-5  p-2 pb-5'>Business Process Enhancement Consultancy</p>
                            </div>

                        </div>
                        <div className='col-3'>
                            <div className='col-11'>
                                <img src={gamser2} alt="" className='img-fluid' />
                            </div>
                            <div className='col-11 mt-4'>
                                <p className='bottombox fs-5  p-2 pb-5'>Business Processes Automation</p>
                            </div>

                        </div>

                        <div className='col-3'>
                            <div className='col-11'>
                                <img src={gamser3} alt="" className='img-fluid' />
                            </div>
                            <div className='col-11 mt-4'>
                                <p className='bottombox fs-5  p-2 pb-5'>Contact and Document Management Automation</p>
                            </div>

                        </div>
                        <div className='col-3'>
                            <div className='col-11'>
                                <img src={gamser4} alt="" className='img-fluid' />
                            </div>
                            <div className='col-11 mt-4'>
                                <p className='bottombox fs-5  p-2 pb-5'>Human Resources Automation Solution</p>
                            </div>

                        </div>
                    </div>
                    <div className=" container d-flex px-5 mt-5">
                        <div className='col-3'>
                            <div className='col-11'>
                                <img src={gamser5} alt="" className='img-fluid' />
                            </div>
                            <div className='col-11 mt-4'>
                                <p className='bottombox fs-5  p-2 pb-5'>AP, AR and Sales Order Automation.</p>
                            </div>

                        </div>
                        <div className='col-3'>
                            <div className='col-11'>
                                <img src={gamser6} alt="" className='img-fluid' />
                            </div>
                            <div className='col-11 mt-4'>
                                <p className='bottombox fs-5  p-2 pb-5'>
                                    Other Digital Transformation  and Automation.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>)}

            <FooterComponent />


        </div>
    )
}

export default ServiceComponent
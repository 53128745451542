import React from 'react'

function LcButton() {

  return (
    <div>
      {localStorage.getItem('lang') === "ind" ? (<div>
        <button class="live-chat-button">
          <img width="40" height="40" src="https://img.icons8.com/ios-filled/50/FFFFFF/filled-speech-bubble-with-dots--v1.png" alt="filled-speech-bubble-with-dots--v1" />Mulai Obrolan</button>
      </div>) : (<div>
        <button class="live-chat-button">
          <img width="40" height="40" src="https://img.icons8.com/ios-filled/50/FFFFFF/filled-speech-bubble-with-dots--v1.png" alt="filled-speech-bubble-with-dots--v1" />Let's Chat</button>
      </div>)}

    </div>
  )
}

export default LcButton
import React from 'react'
import home from '../assets/img/home.webp';
import LcButton from '../LcButton';
import '../assets/homestyle.css';
import 'animate.css';
import gambar1 from '../assets/img/slide1.jpg';
import gambar2 from '../assets/img/slide2.jpeg';
import gambar3 from '../assets/img/gambar3.jpg';
import DetailImage from './DetailImage'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import FooterComponent from './FooterComponent';
function HomeComponent() {

    const [t, i18n] = useTranslation("global")

    return (
        <div className='animate_animated animate_fadeIn'>
            {localStorage.getItem('lang') === "ind" ? (
                <div>
                    {/* <div className="d-flex justify-content-center mt-5">
                    <div className=''>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}

                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                          
                            style={{ width: '500px' }}
                        >
                            <SwiperSlide>
                                <div className='col-12' >
                                    <img src={gambar1} className='img-fluid' />
                                </div>

                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='col-12 '>
                                    <img src={gambar2} className='img-fluid' />
                                </div>

                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='col-12'>
                                    <img src={gambar3} className='img-fluid' />
                                </div>

                            </SwiperSlide>
                        </Swiper>
                    </div>
                    </div> */}
                     <div className="d-flex justify-content-center mt-5">
                    <div className="col-md-8 ">
                        <img src={home} alt="" className='img-fluid' />
                    </div>
                </div>
                    

                    <p className='hometext  text-center lh-1 mt-5'>Bawalah Perusahaan Anda Menuju Jalan Kesuksesan</p>
                    <h1 className='hometext2 text-center '>Meriset. Menerapkan. Mengoperasikan</h1>

                    <div className='d-flex justify-content-center mt-4'>
                        <button className='buttoncontact btn-block'>HUBUNGI SEKARANG !</button>
                    </div>
                    <LcButton />


                    <div className="container mt-5">
                        <div className="group d-flex justify-content-center">
                            <div className="item col-3">
                                <h3>TENTANG KAMI</h3>
                                <div className='line mb-4'></div>
                                <p><span className='fw-bold'>ACCEL WORKS INDONESIA  </span>bekerja secara langsung dengan pelanggan untuk memberikan pelayanan yang terbaik dalam mengidentifikasi perkembangan peluang bisnis mereka dengan riset serta memberikan solusi untuk membantu perjalanan digital mereka menuju digital     
                                    <span className='fw-bold'><italic className="fw-bold"> Information-led Digital Transformation.</italic>  </span></p>
                                <p>BACA SELENGKAPNYA</p>
                            </div>
                            <div className="item col-3">
                                <h3>VISI KAMI</h3>
                                <div className='line mb-4'></div>
                                <ul className='homelist'>
                                    <li><span>F - Focus </span>dalam memberikan konsultasi praktis yang terjangkau dalam Peningkatan Proses Bisnis.</li>
                                    <li><span>I -</span>Menambah Nilai bagi pelanggan kami melalui solusi <span><italic>Information Technology</italic></span> terbaru.</li>
                                    <li><span>T -</span>Membantu pelanggan dalam<span><italic>Tranformation Journey</italic> </span>untuk mencapai efisiensi, pertumbuhan bisnis, dan profitabilitas yang lebih baik.
                                    </li>
                                </ul>
                                <p>BACA SELENGKAPNYA</p>
                            </div>
                            <div className="item col-3">
                                <h3>LAYANAN</h3>
                                <div className='line mb-4'></div>
                                <ul className='homelist'>
                                    <li>Konsultasi Peningkatan Proses Bisnis.</li>
                                    <li>Penerapan dan dukungan Otomasi Proses Bisnis.</li>
                                    <li>Penerapan dan dukungan Otomasi Manajemen Dokumen dan Kontrak.</li>
                                    <li>Penerapan dan dukungan Otomasi Manajemen Sumber Daya Manusia.</li>
                                    <li>AP Finansial, AR, penerapan dan dukungan Otomasi Entri Pesanan Penjualan.</li>
                                    <li>Penerapan dan dukungan Autmasi Transformasi Digital lainnya.</li>
                                </ul>
                                <p>BACA SELENGKAPNYA</p>
                            </div>
                        </div>
                    </div></div>
            ) : (<div>
                <div className="d-flex justify-content-center mt-5">
                    <div className="col-md-8 ">
                        <img src={home} alt="" className='img-fluid' />
                    </div>
                </div>

                <p className='hometext  text-center lh-1 mt-5'>Get your company on the path to success</p>
                <h1 className='hometext2 text-center '>Discovery. Implementation. Operate.</h1>

                <div className='d-flex justify-content-center mt-4'>
                    <button className='buttoncontact btn-block'>CONTACT US TODAY !</button>
                </div>
                <LcButton />


                <div className="container mt-5">
                    <div className="group d-flex justify-content-center">
                        <div className="item col-3">
                            <h3>ABOUT US</h3>
                            <div className='line mb-4'></div>
                            <p><span className='fw-bold'>ACCEL WORKS WORLDWIDE PTE LTD </span>work closely with our customers to deliver high value services assisting them to identify business processes improvement opportunities through discovery and provide solution that will assist them in their Digital Journey through their
                                <span className='fw-bold'> Information-led Digital Transformation.</span></p>
                            <p>READ MORE</p>
                        </div>
                        <div className="item col-3">
                            <h3>OUR VISION</h3>
                            <div className='line mb-4'></div>
                            <ul className='homelist'>
                                <li><span>F - Focus</span>in providing affordable practical consultancy in Business Processes Improvement.</li>
                                <li><span>I -</span>Add Value to our customers through latest <span>Information Technology </span>solution.</li>
                                <li><span>T -</span>Assist customers in<span>Transformation Journey </span>to achieve better efficiency, business growth and profitability.
                                </li>
                            </ul>
                            <p>READ MORE</p>
                        </div>
                        <div className="item col-3">
                            <h3>SERVICES</h3>
                            <div className='line mb-4'></div>
                            <ul className='homelist'>
                                <li>Business Process Enhancement Consultancy.</li>
                                <li>Business Process Automation implementation and support.</li>
                                <li>Contract and Document Management  Automation implementation and support.</li>
                                <li>Human Resources Management Automation implementation and support.</li>
                                <li>Financial AP, AR, Sales Order Entry Automation implementation and support.</li>
                                <li>Other Digital Transformation Autmation implementation and support.</li>
                            </ul>
                            <p>READ MORE</p>
                        </div>
                    </div>
                </div>
            </div>)}

            <FooterComponent />
        </div>
    )
}

export default HomeComponent
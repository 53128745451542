import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import logo from '../assets/img/logo.webp';
import '../assets/maincontent.css';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import logo2 from '../assets/img/logocontent2.png';
import solution1 from '../assets/img/solution1.webp';
import solution2 from '../assets/img/solution2.webp';
import solution3 from '../assets/img/solution3.webp';
import solution4 from '../assets/img/solution4.webp';
import solution5 from '../assets/img/solution5.webp';
import solution6 from '../assets/img/solution6.webp';
import option1 from '../assets/img/option1.webp';
import option2 from '../assets/img/option2.webp';
import option3 from '../assets/img/option3.webp';
import LcButton from '../LcButton';
import FooterComponent from './FooterComponent';
import home from '../assets/img/home.webp';
function MainContent() {
    return (

        <div>
            {localStorage.getItem('lang') == "ind" ? (

                <div>


                    <div className="content2 py-4 mt-5">
                        <div className=" d-flex justify-content-center">
                            <div className="col-4">
                                <img src={logo2} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <p className='text-center fst-italic fw-bold mt-3'>Transformasi operasi Anda secara digital dan tingkatkan aliran informasi untuk produktivitas yang lebih besar</p>
                    </div>
                    <div className=" d-flex justify-content-center mt-5">

                        <div className="col-md-6 col-8">
                            <p className='textall text-center '><span className='fw-bold'>DocLink </span>adalah solusi digital yang mengelola informasi dokumen dan alur proses secara otomatis – menghemat banyak waktu dengan meniadakan seluruh proses manual perusahaan Anda.</p>
                        </div>
                    </div>
                    <div className=" d-flex justify-content-center mt-3 ">

                        <div className="col-md-6 col-8">
                            <p className='textall text-center '>Dengan mengurangi kertas dan mengotomatiskan tugas di departemen mana pun, DocLink membantu Anda merampingkan operasi, dan meningkatkan visibilitas dan kontrol ke seluruh siklus hidup dokumen Anda.</p>

                        </div>


                    </div>
                    <div className=" d-flex justify-content-center ">

                        <button className='buttonstart py-2'>Memulai</button>

                    </div>
                    <LcButton />
                    <div className="content4 d-flex justify-content-center align-items-center mt-5 py-5">
                        <div className="col-md-4 col-3">
                            <p>Manfaat DocLink</p>
                        </div>
                        <div className="col-md-4 col-6">
                            <ul>
                                <li>​​Akses mudah ke dokumen</li>
                                <li>Proses persetujuan yang disederhanakan</li>
                                <li>Peningkatan produktivitas dan kolaborasi</li>
                                <li>Mengurangi kesalahan manual</li>
                                <li>Keamanan yang kuat dan fleksibel</li>
                            </ul>
                        </div>

                    </div>

                    <div className="content5 mt-5">
                        <p className='title text-center '>Solusi DocLink</p>
                        <div className="d-flex justify-content-around">
                            <div className="col-md-3 col-2">
                                <img src={solution1} alt="" className='d-block m-auto img-fluid' />
                                <p className='teks2 text-center'>Pengelolaan Dokumen</p>
                                <p className='teks3 text-center mt-5'>Kelola data dan konten Anda yang terisolasi di seluruh perusahaan</p>
                            </div>
                            <div className="col-md-3 col-2">
                                <img src={solution2} alt="" className='d-block m-auto img-fluid' />
                                <p className='teks2 text-center'>Alur Kerja dan Pemrosesan Dokumen</p>
                                <p className='teks3 text-center mt-5'>Otomatiskan dan kontrol data dan dokumen Anda</p>
                            </div>
                            <div className="col-md-3 col-2">
                                <img src={solution3} alt="" className='d-block m-auto img-fluid' />
                                <p className='teks2 text-center'>Pengambilan Dokumen</p>
                                <p className='teks3 text-center mt-5'>Ambil dokumen Anda bagaimanapun caranya dibuat

                                    atau diterima</p>
                            </div>
                        </div>
                    </div>
                    <div className=" d-flex justify-content-around mt-5">
                        <div className="col-md-3 col-2">
                            <img src={solution4} alt="" className='d-block m-auto img-fluid' />
                            <p className='teks2 text-center'>Pengiriman Otomatis</p>
                            <p className='teks3 text-center mt-5'>Jadwalkan dan kirim dokumen secara otomatis ke pelanggan dan partner bisnis Anda</p>
                        </div>
                        <div className="col-md-3 col-2">
                            <img src={solution5} alt="" className='d-block m-auto img-fluid' />
                            <p className='teks2 text-center'>OCR Tangkapan Cerdas</p>
                            <p className='teks3 text-center mt-5'>Mengurangi pekerjaan manual Anda dalam menangkap dan mengindeks dokumen menggunakan OCR berbasis AI</p>
                        </div>
                        <div className="col-md-3 col-2">
                            <img src={solution6} alt="" className='d-block m-auto img-fluid' />
                            <p className='teks2 text-center'>Formulir Cerdas</p>
                            <p className='teks3 text-center mt-5'>Konfigurasi formulir DocLink Anda sendiri yang dipersonalisasi untuk membuat, mengindeks, dan memproses dokumen secara otomatis</p>
                        </div>
                    </div>
                    <div className="content6 mt-5 py-5">
                        <p className='title text-center'>Opsi Penerapan DocLink</p>
                        <div className=" d-flex justify-content-center mt-5">
                            <div className="col-7">
                                <p className='textall text-center'>Baik organisasi Anda memindahkan semuanya ke cloud, tetap di lokasi, atau campuran keduanya, DocLink menawarkan fleksibilitas dan opsi bagi Anda untuk menjaga agar tim tetap beroperasi dalam situasi apa pun.</ p>
                            </div>
                        </div>
                        <div className=" d-flex justify-content-evenly mt-5">
                            <div className="col-2">
                                <img src={option1} alt="" className='img-fluid d-block m-auto' />
                                <p className='textall text-center mt-4 fw-bold'>Di Tempat Sendiri</p>
                            </div>
                            <div className="col-2">
                                <img src={option2} alt="" className='img-fluid d-block m-auto' />
                                <p className=' textall text-center mt-4 fw-bold'>Hibrida</p>
                            </div>
                            <div className="col-2">
                                <img src={option3} alt="" className='img-fluid d-block m-auto' />
                                <p className='textall text-center mt-4 fw-bold'>Cloud</p>
                            </div>
                        </div>
                    </div>
                    <div className="content7 py-5">
                         <p className='title text-center'>Bermitra dengan DocLink</p>
                         <div className=" d-flex justify-content-center mt-4">
                             <div className="col-8">
                                 <p className='textall text-center'>Accel Works bangga menjadi distributor regional untuk DocLink, berbagi visi yang sama dalam memberikan solusi inovatif kepada organisasi di wilayah ini untuk bergerak menuju digitalisasi.

                                 </p>
                             </div>
                         </div>
                     </div>
                     <div className="content8">
                         <p className='title text-center pt-5'>Membantu menghubungkan orang, proses, dan data</p>
                     </div>
                     <div className=" d-flex justify-content-center mt-4">
                        <div className="col-md-8 col-10">
                            <p className='textall text-center'>Konsultasikan dengan spesialis kami untuk mempelajari lebih lanjut tentang mendigitalkan operasi organisasi Anda dengan DocLink.</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <div className="col-md-2 col-3">
                            <label htmlFor="">Nama Depan</label><br />
                            <input type="text" /><br />
                            <label htmlFor="">Nama Belakang</label><br />
                            <input type="text" /><br />
                            <label htmlFor="">Email *</label><br />
                            <input type="text" /><br />
                            <label htmlFor="">Subjek * </label><br />
                            <input type="text" /><br />
                            <button className='buttonsubmitcontact mt-3'>Kirim</button>
                        </div>
                        <div className="col-2">
                            <label htmlFor="">Tinggalkan kami pesan....</label>
                            <textarea name="" id="" cols="30" rows="9" className='rezize'></textarea>

                        </div>
                        
                    </div>
                    <FooterComponent/>
                </div>
            ) : (
                <div>


                    <div className="content2 py-4 mt-5">
                        <div className=" d-flex justify-content-center">
                            <div className="col-4">
                                <img src={logo2} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <p className='text-center fst-italic fw-bold mt-3'>Digitally transform your operations and improve information flow for greater productivity</p>
                    </div>
                    <div className=" d-flex justify-content-center mt-5">

                        <div className="col-md-6 col-8">
                            <p className='textall text-center '><span className='fw-bold'>DocLink </span>is a digital solution that manages document information and process flow automatically – saving a great amount of time by eliminating manual processes throughout your enterprise.</p>
                        </div>
                    </div>
                    <div className=" d-flex justify-content-center mt-3 ">

                        <div className="col-md-6 col-8">
                            <p className='textall text-center '>By going paperless and automating tasks in any department, DocLink helps you streamline operations, and improve visibility and control to your entire document lifecycle.</p>

                        </div>


                    </div>
                    <div className=" d-flex justify-content-center ">

                        <button className='buttonstart py-2'>Get Started</button>

                    </div>
                    <LcButton />
                    <div className="content4 d-flex justify-content-center align-items-center mt-5 py-5">
                        <div className="col-md-4 col-3">
                            <p>Benefits of DocLink</p>
                        </div>
                        <div className="col-md-4 col-6">
                            <ul >
                                <li>​​Easy access to documents</li>
                                <li>Streamlined approval process</li>
                                <li>Increased productivity and collaboration</li>
                                <li>Reduced manual errors</li>
                                <li>Powerful and flexible security</li>
                            </ul>
                        </div>

                    </div>

                    <div className="content5 mt-5">
                        <p className='title text-center '>DocLink Solutions</p>
                        <div className=" d-flex justify-content-around">
                            <div className="col-md-3 col-2">
                                <img src={solution1} alt="" className='d-block m-auto img-fluid' />
                                <p className='teks2 text-center'>Document Management</p>
                                <p className='teks3 text-center mt-5'>Manage your isolated data and content across the enterprise</p>
                            </div>
                            <div className="col-md-3 col-2">
                                <img src={solution2} alt="" className='d-block m-auto img-fluid' />
                                <p className='teks2 text-center'>Workflow and Document Processing</p>
                                <p className='teks3 text-center mt-5'>Automate and control your data and documents</p>
                            </div>
                            <div className="col-md-3 col-2">
                                <img src={solution3} alt="" className='d-block m-auto img-fluid' />
                                <p className='teks2 text-center'>Document Capture</p>
                                <p className='teks3 text-center mt-5'>Capture your documents no matter how they are created

                                    or received</p>
                            </div>
                        </div>
                    </div>
                    <div className=" d-flex justify-content-around mt-5">
                        <div className="col-md-3 col-2">
                            <img src={solution4} alt="" className='d-block m-auto img-fluid' />
                            <p className='teks2 text-center'>Automated Delivery</p>
                            <p className='teks3 text-center mt-5'>Schedule and automatically deliver documents to your customers and business partners</p>
                        </div>
                        <div className="col-md-3 col-2">
                            <img src={solution5} alt="" className='d-block m-auto img-fluid' />
                            <p className='teks2 text-center'>Smart Capture OCR</p>
                            <p className='teks3 text-center mt-5'>Reduce your manual work capturing and indexing documents using AI-driven OCR</p>
                        </div>
                        <div className="col-md-3 col-2">
                            <img src={solution6} alt="" className='d-block m-auto img-fluid' />
                            <p className='teks2 text-center'>Smart Forms</p>
                            <p className='teks3 text-center mt-5'>Configure your own personalized DocLink forms to automatically create, index, and process documents</p>
                        </div>
                    </div>
                    <div className="content6 mt-5 py-5">
                        <p className='title text-center'>DocLink Deployment Options</p>
                        <div className=" d-flex justify-content-center mt-5">
                            <div className="col-7">
                                <p className='textall text-center'>Whether your organization is moving everything to the cloud,keeping on-premise, or a mix of both, DocLink offers the flexibilityand options for you to keep your teams operational in any situation.</p>
                            </div>
                        </div>
                        <div className=" d-flex justify-content-evenly mt-5">
                            <div className="col-2">
                                <img src={option1} alt="" className='img-fluid d-block m-auto' />
                                <p className='textall text-center mt-4 fw-bold'>On-Premise</p>
                            </div>
                            <div className="col-2">
                                <img src={option2} alt="" className='img-fluid d-block m-auto' />
                                <p className=' textall text-center mt-4 fw-bold'>Hybrid</p>
                            </div>
                            <div className="col-2">
                                <img src={option3} alt="" className='img-fluid d-block m-auto' />
                                <p className='textall text-center mt-4 fw-bold'>Cloud</p>
                            </div>
                        </div>
                    </div>
                    <div className="content7 py-5">
                        <p className='title text-center'>In Partnership with DocLink</p>
                        <div className=" d-flex justify-content-center mt-4">
                            <div className="col-8">
                                <p className='textall text-center'>Accel Works is proud to be a regional distributor for DocLink,sharing the same vision of delivering innovative solutions to organizations in the region to move towards digitalisation.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="content8">
                        <p className='title text-center pt-5'>Helping to connect people, processes and data</p>
                    </div>
                    <div className=" d-flex justify-content-center mt-4">
                        <div className="col-md-8 col-10">
                            <p className='textall text-center'>Get consulted by our specialists to explore more into digitizing your organization’s operations with DocLink.</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <div className="col-md-2 col-3">
                            <label htmlFor="">First Name</label><br />
                            <input type="text" /><br />
                            <label htmlFor="">Last Name</label><br />
                            <input type="text" /><br />
                            <label htmlFor="">Email *</label><br />
                            <input type="text" /><br />
                            <label htmlFor="">Subject * </label><br />
                            <input type="text" /><br />
                        </div>
                        <div className="col-2">
                            <label htmlFor="">Leave us an message....</label>
                            <textarea name="" id="" cols="30" rows="9" className='rezize'></textarea>

                        </div>
                    </div>
                    <FooterComponent />
                </div>
            )}


        </div>







    )
}

export default MainContent
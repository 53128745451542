import React from 'react'
import FooterComponent from '../components/FooterComponent'
import '../assets/contactstyle.css';
function ContactComponent() {
  return (
    <div>
      {localStorage.getItem('lang') == "ind"  ? (
        <div >
          <div className="content1">
            <div className="headservice col-7 mt-5 offset-1">
              <h1 className='title'>Kontak</h1>
              <hr className='line'></hr>
              <p className=' lh-lg'>Gatot Subroto Nomor .74-75, RT.2/RW.1, Menteng Dalam, Tebet , Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12870</p>
              <p>info@accelworks.id</p>
            </div>

          </div>
          <div className='container'>
          <div className='d-flex justify-content-center mt-5'>
            <form className='formcontact '>

              <label htmlFor=""> Nama Depan *</label><br />
              <input htmlFor="text" /><br />
              <label htmlFor="">Nama Belakang *</label><br />
              <input type="text" /><br />
              <label htmlFor="">Email *</label><br />
              <input type="text" /><br />
              <label htmlFor="">Subjek * </label><br />
              <input type="text" /><br />
              <label htmlFor="">Pesan *</label><br />
              <textarea name="" id="" ></textarea><br />
              <button className='buttonsubmitcontact'>Kirim</button>

            </form>
            <div className='formcontact ' >
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1643283518!2d106.83964077510477!3d-6.242062161122846!2m3!1f0!2f0!3f0!3m2! 1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3bf5252f3af%3A0x1f27e45b12c0e6d6!2sGraha%20Mustika%20Ratu!5e0!3m2!1sen!2sid!4v1688365879976!5m2! 1sen!2sid"  style={{ "border": 0 }} className='img-fluid' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
          </div>
         
        </div>
      ) : (
        <div>

          <div className="content1">
            <div className="headservice col-7 mt-5 offset-1">
              <h1 className='title'>Contact</h1>
              <hr className='line'></hr>
              <p className='lh-lg'>Gatot Subroto Number .74-75, RT.2/RW.1, Menteng Dalam, Tebet , City of South Jakarta, Special Capital Region of Jakarta 12870</p>
              <p>info@accelworks.id</p>
            </div>

          </div>
          <div className='container'>
          <div className='d-flex justify-content-center mt-5'>
            <form className='formcontact '>

              <label htmlFor=""> First Name *</label><br />
              <input htmlFor="text" /><br />
              <label htmlFor="">Last Name *</label><br />
              <input type="text" /><br />
              <label htmlFor="">Email *</label><br />
              <input type="text" /><br />
              <label htmlFor="">Subject * </label><br />
              <input type="text" /><br />
              <label htmlFor="">Message *</label><br />
              <textarea name="" id="" ></textarea><br />
              <button className='buttonsubmitcontact'>Submit</button>

            </form>
            <div className='formcontact ' >
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1643283518!2d106.83964077510477!3d-6.242062161122846!2m3!1f0!2f0!3f0!3m2! 1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3bf5252f3af%3A0x1f27e45b12c0e6d6!2sGraha%20Mustika%20Ratu!5e0!3m2!1sen!2sid!4v1688365879976!5m2! 1sen!2sid"  style={{ "border": 0 }} className='img-fluid' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
          </div>
        </div>)}

      <FooterComponent />
    </div>
  )
}

export default ContactComponent
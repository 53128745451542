import React from 'react';

import FooterComponent from './FooterComponent';

function DetailImage() {
    return (
        <div>
            <p>Hello World</p>
           
        </div>
    )
}

export default DetailImage
import React from 'react'
import '../assets/footer.css';
function FooterComponent() {
  return (
    <div>
      {localStorage.getItem('lang')==="ind"?(<div className='footer'>
      <div className=" d-flex justify-content-around align-items-center">
        <div className="col-md-3 col-4">
          <p className='cr'>© 2022 oleh Accel Works Worldwide Pte Ltd </p>
        </div>
        <div className="col-md-7 col-6 d-flex justify-content-around">
          <p>BERANDA</p>
          <p>TENTANG</p>
          <p>LAYANAN</p>
          <p>DOCLINK</p>
          <p>KONTAK</p>
        </div>

      </div>



      <div className="container">
        {/* <div className=" d-flex justify-content-end">
          <div className="col-1">
            <img width="48" height="48" src="https://img.icons8.com/material-rounded/48/FFFFFF/facebook-new.png" alt="facebook-new" className='icon' />
          </div>
          <div className="col-1">
            <img width="48" height="48" src="https://img.icons8.com/ios-filled/50/FFFFFF/twitter-circled--v1.png" alt="twitter-circled--v1" className='icon' />
          </div>
        </div> */}


      </div>
      </div>):(<div className='footer'>
      <div className=" d-flex justify-content-around align-items-center">
        <div className="col-md-3 col-4">
          <p className='cr'>© 2022 by Accel Works Worldwide Pte Ltd </p>
        </div>
        <div className="col-md-7 col-6 d-flex justify-content-around">
          <p>HOME</p>
          <p>ABOUT</p>
          <p>SERVICES</p>
          <p>DOCLINK</p>
          <p>CONTACT</p>
        </div>

      </div>



      <div className="container">
        {/* <div className=" d-flex justify-content-end">
          <div className="col-1">
            <img width="48" height="48" src="https://img.icons8.com/material-rounded/48/FFFFFF/facebook-new.png" alt="facebook-new" className='icon' />
          </div>
          <div className="col-1">
            <img width="48" height="48" src="https://img.icons8.com/ios-filled/50/FFFFFF/twitter-circled--v1.png" alt="twitter-circled--v1" className='icon' />
          </div>
        </div> */}


      </div>
      </div>)}
     

    </div>



  )
}

export default FooterComponent